<template>
    <b-row>
        <b-col lg="12">
            <b-card :style="statutStyles">
                <!-- table -->
                <vue-good-table
                    :ref="fullVgtContext"
                    :columns="columns"
                    :rows="affaires"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :sortOptions="sortOptions"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table striped bordered tableau"
                    >
                    
                    
                    
                >
                    <template #emptystate>
                        <div class="text-center"> Aucune Affaire n'a été trouvée </div>
                    </template>

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="editAffaire(props.row.id)"
                            >
                                <feather-icon icon="Edit2Icon" size="1x"/>
                            </b-button>

                            <b-button
                                size="sm"
                                v-if="deletable(props.row)"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="confirmDeleteAffaire(props.row.id, props.row.num != null ? props.row.num : props.row.titre, removeAffaireFromList)"
                            >
                                <feather-icon icon="TrashIcon" size="1x"/>
                            </b-button>
                        </span>

                        <!-- Voir si pas supporté nativement par vue good table -->
                         <template v-else-if="props.column.field === 'statut'">
                            <div  class="text-nowrap" :style="{backgroundColor: props.row.statut.color}">{{props.row.statut.libelle}}</div>
                        </template>

                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import remember_filters_mixins from "@/views/lists/remember_filters_mixins.js"

    import { VueGoodTable } from 'vue-good-table'

    import affaireApi from "@/api/affaireApi"
    import miscApi from "@/api/miscApi"
    import { formatNumber, itemMatch, sortObjectsOnAttribute,getBgColor } from "@/services/utils"
    import { confirmDeleteAffaire, deleteAffaire } from "@/views/setups/affaire"

    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import { mapState, mapGetters, mapMutations } from "vuex"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    const vgtContext = "listAffaires"

    export default {
        mixins: [remember_filters_mixins],
        components: {
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                vgtContext,
                affaires: [],
                sortOptions: {enabled: true, initialSortBy: [{field: 'num', type: 'desc'}]},
                pageLength: 25,
                columns: [
                    {
                        label: 'N°',
                        field: 'num',
                        filterOptions: {enabled: true,placeholder: 'AFF-...',},
                        
                        
                    },
                    {
                        label: 'Intitulé',
                        field: 'titre',
                        filterOptions: {enabled: true,placeholder: 'Intitulé...',},
                    },
                    {
                        label: 'Agence',
                        field: 'agence',
                        filterOptions: {enabled: true,placeholder: 'Agence...', filterFn: (agence, filter) => itemMatch(agence, ["nom", "numero"], filter)},
                        formatFn: (agence) => agence.numero + '-' + agence.nom,
                        sortFn: (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'nom')
                    },
                    {
                        label: 'Client',
                        field: 'client.nom',
                        filterOptions: {enabled: true,placeholder: 'Client...',},
                    },
                    {
                        label: 'Type',
                        field: 'type',
                        filterOptions: {enabled: true, placeholder: 'Type...', filterDropdownItems: [], filterFn: (selected, code) => typeof selected != 'undefined' && selected.code == code},
                        formatFn: (type) => type.libelle,
                        sortFn: (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'libelle')
                    },
                    {
                        label: 'Statut',
                        field: 'statut',
                        filterOptions: {enabled: true, placeholder: 'Statut...', filterDropdownItems: [], filterFn: (selected, code) => selected.code == code},
                        sortFn: (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'libelle'),
                        tdClass: this.tdClassFunc,
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        width: '10%',
                        sortable: false
                    },
                ],
            }
        },

        beforeMount() {
            this.refreshAffaires();
            this.refreshStatuts();
            this.refreshTypes();

            // si l'utilisateur voit les decs de tous les chargés d'affaire, on rajoute la colonnne
            if(!this.showDecsOnlyUser) {
                this.columns.splice(3, 0, {
                    label: "Chargé Affaire",
                    field: "userChargeAffaire",
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Chargé Affaire ...',
                        filterFn: (user, filter) => itemMatch(user, ["firstname", "lastname", "username"], filter)
                    },
                    formatFn: (userValidationChargeAffaire) => `${userValidationChargeAffaire.firstname} ${userValidationChargeAffaire.lastname} - ${userValidationChargeAffaire.username}`,
                    sortFn:  (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'firstname')
                })
            }
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, deleteAffaire, confirmDeleteAffaire, formatNumber}
        },

        computed: {
            ...mapState({
                user: state => state.data.user,
                statuts: state => state.data.affaireStatuts,
                types: state => state.data.affaireTypes,
            }),
            ...mapGetters("data", ["showDecsOnlyUser"]),
            statutStyles() {
                let statutStyles = this.statuts.reduce((acc, statut) => {
                    let attr = '--bg-' + statut.code;
                    acc[attr] = statut.color;
                    attr = '--color-' + statut.code;
                    acc[attr] = getBgColor(statut.color)['color'];
                    return acc;
                },{});
                return statutStyles;
            }
        },

        methods: {

            tdClassFunc(row) {
                return row.statut.code
            },


            ...mapMutations("data", {
                setStatuts: "setAffaireStatuts",
                setTypes: "setAffaireTypes",
            }),
            handleCustomFilter(value) {
                // TODO filtre date
            },

            async refreshAffaires() {
                this.affaires = await affaireApi.getAffaires(this.showDecsOnlyUser ? {userChargeAffaire: this.user.id} : null);
            },

            async refreshStatuts() {
                /* console.log(process.env.NODE_ENV == "development") */
                let statuts = await miscApi.getAffaireStatuts();
                this.setStatuts(statuts);
                this.columns.find(c => c.field == "statut").filterOptions.filterDropdownItems = this.statuts.map(s => ({text: s.libelle, value: s.code}))
            },

            async refreshTypes() {
                let types = await miscApi.getAffaireTypes();
                this.setTypes(types);
                this.columns.find(c => c.field == "type").filterOptions.filterDropdownItems = this.types.map(s => ({text: s.libelle, value: s.code}))
            },


            editAffaire(affaireId) {
                this.saveFilters();
                this.$router.push({path: `/affaires/${affaireId}/edit` })
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            deletable(affaire) {
                // on ne supprime que les DECs brouillon, et si l'user est Chargé Affaire, alors il ne supprime que les siens
                return affaire.statut.code == "BROUILLON" && (!this.showDecsOnlyUser || affaire.userChargeAffaire.id == this.user.id)
            },

            removeAffaireFromList(affaireId) {
                let index = this.affaires.findIndex(affaire => affaire.id == affaireId)
                if(index != -1)
                    this.affaires.splice(index, 1)
            }
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }

    td.ENCOURS {
        background-color: var(--bg-ENCOURS);
        color: var(--color-ENCOURS) !important;
    }

    td.FINANCIER {
        background-color: var(--bg-FINANCIER);
        color: var(--color-FINANCIER) !important;
    }

    td.TECHNIQUE {
        background-color: var(--bg-TECHNIQUE);
        color: var(--color-FINANCIER) !important;
    }
</style>